import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import Typography from 'components/Typography';
import Select from 'components/Select';
import Input from 'components/Input';
import { 
  Container,
  Content,
  Row,
  Body,
  DocumentItem,
  DivAdd,
  DivMargin,
  ContentChip,
  Link,
  EnterpriseAdd,
  LocationTwoRow,
  LocationThreeRow
} from './styles';
import FileInput from 'components/FileInput';
import { useFormContext, Controller } from 'react-hook-form';
import { cnpjMask, cpfMask, phoneMask, cepMask, onlyNumber } from 'utils/maskUtils';
import { LawsService } from 'services/laws';
import { LoaderContainer } from '../styles';
import { CircularProgress, InputLabel } from '@material-ui/core';
import SelectMultiple from 'components/SelectMultiple';
import { Chip } from '@material-ui/core';
import { CepService } from 'services/cep';
import { ProponentService } from 'services/proponent';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import './style.css';
import DragAndDrop from 'components/DragAndDrop';
import { UpdateTaxRegime } from 'components/AdmProjectsTable/AdmProjectsTableDetails/Modals';

import { validateFileExtension, validateFileSize } from 'utils/validation';

const RegistrationData = ({ loading, legalPerson, idProponent, setIsDisabled, currentStep, isUploading }) => {
  const { control, setValue, setError, getValues, clearErrors, formState: { errors } } = useFormContext();

  const [personType, setPersonType] = useState();
  const [companySize, setCompanySize] = useState();
  const [taxRegime, setTaxRegime] = useState();
  const [legalNature, setLegalNature] = useState();
  const [laws, setLaws] = useState([]);
  const [yearsExperience, setYearsExperience] = useState();
  const [incentiveLaws, setIncentivelaws] = useState();
  const [selectedLaws, setSelectedLaws] = useState([]);
  const valuesLawRef = useRef();
  valuesLawRef.current = selectedLaws;
  const theme = useTheme();
  const [companyNames, setCompanyNames] = useState('');
  const [companyNamesArray, setCompanyNamesArray] = useState([]);

  
  const [disabled, setDisabled] = useState(false);
  const [cepError, setCepError] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const isDisabled = getValues('register_status') === 'Em análise' || getValues('register_status') === 'Concluído';
  setIsDisabled(isDisabled);

  const years_experience = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1} ${i + 1 > 1 ? 'anos' : 'ano'}`,
      })),
    [],
  );
  const legalPersons = useMemo(
    () => [
      'Pessoa física',
      'Entidade Empresarial',
      'Entidade sem fins lucrativos',
      'Administração Pública Federal',
      'Administração Pública Estadual',
      'Administração Pública Municipal'
    ],
    [],
  );

  const taxs_regime = useMemo(
    () => [
      'Não se aplica',
      'Lucro real',
      'Lucro presumido',
      'Simples nacional',
    ],
    [],
  );

  const taxs_regime_empresa = useMemo(
    () => [
      'Não se aplica',
      'Lucro real',
      'Lucro presumido',
      'Simples nacional',
    ],
    [],
  );

  const companySizes = useMemo(
    () => [
      'MEI',
      'ME',
      'EPP',
      'EI',
      'EIRELI'
    ],
    [],
  );

  const findCep = useCallback(
    async cep => {
      if(getValues('previous_cep') !== cep && !disabled){
        setDisabled(true);
        setCepError(undefined);
        try {
          const response = await CepService.find(cep);
          if (!response.data.erro) {
            setValue('public_place', response.data.logradouro);
            setValue('district', response.data.bairro);
            setValue('state', response.data.uf);
            setValue('city', response.data.localidade);
          } else {
            setCepError("CEP inválido");
          }
          setValue('previous_cep', cep)
        } catch (err) {
          toast.error('Falha ao pesquisar endereço');
        }
        setDisabled(false);
      }
    },
    [setValue]
  );

  const saveTaxRegime = useCallback(async (data) => {
    try {
      const response = await ProponentService.updateProponentTaxRegime(idProponent, { tax_regime: taxRegime });
      console.log(response);
      toast.success('Regime tributário atualizado com sucesso');
      setTimeout(function () { window.location.reload(1); }, 1000);
    } catch (err) {
      console.log('err', err)
      toast.error('Falha ao atualizar o regime tributário');
    }
  }, [taxRegime]);

  const handleAddCompany = useCallback(() => {
    setCompanyNamesArray([...companyNamesArray, companyNames]);
    setCompanyNames('');
  }, [companyNamesArray, companyNames]);

  const handleDeleteCompanyNamesArray = chipToDelete => {
    setCompanyNamesArray(chips =>
      chips.filter(chip => chip !== chipToDelete),
    );
  };

  const mounted_company_names = useRef();
  const company_names = getValues('company_names');
  let exploded_company_names = company_names ? company_names.split(',') : [];

  useEffect(() => {
    if (!mounted_company_names.current) {
      setCompanyNamesArray(exploded_company_names);
      mounted_company_names.current = true;
    } else {
      setValue('company_names', companyNamesArray.join(','));
    }
  }, [setValue, companyNamesArray]);

  useEffect(() => {
    if (getValues('incentive_laws')) {
      setIncentivelaws(getValues('incentive_laws'));
    }
    let valuesFilter = getValues('incentive_laws');
    if (typeof valuesFilter == 'string') {
      if (valuesFilter.length > 0) {
        const arry = valuesFilter.split(',');
        arry && setSelectedLaws(arry);
      }
    } else {
      getValues('incentiv_laws') && setSelectedLaws(getValues('incentiv_laws'));
    }
    if (getValues('years_experience')) {
      setYearsExperience(getValues('years_experience'));
    }
    if (getValues('tax_regime')) {
      setTaxRegime(getValues('tax_regime'));
    }
  }, [getValues]);

  const loadLaws = useCallback(async () => {
    try {
      const response = await LawsService.list();
      setLaws(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadLaws();

    if (legalPerson || getValues('legalPerson')) {
      setPersonType(legalPersons.findIndex(
        item => item === (getValues('legalPerson') || legalPerson),
      ));
    }
  }, [loadLaws, legalPerson, legalPersons, currentStep, getValues]);

  const renderFiledPhysicalPerson = useCallback(() => {
    return (
      <>
      {/* *Obrigatório */}
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              label="Nome Fantasia (PJ) ou Nome Social (PF)"
              {...field}
              disabled={isDisabled}
              error={errors["name"]?.message}
            />
          )}
        />
        <Row colSize={2}>
          {/* *Obrigatório */}
          <Controller
            name="full_name"
            control={control}
            render={({ field }) => {
              field.value = getValues('full_name');
              return (
                <Input
                  label="Nome completo"
                  {...field}      
                  required={true}    
                  margin={84}        
                  disabled={isDisabled}
                  error={errors["full_name"]?.message}
                />
              );
            }}
          />
          {/* *Obrigatório */}
          <Controller
            name="cpf"
            control={control}
            render={({ field }) => {
              field.value = getValues('cpf');
              return(
                <Input
                  label="CPF"
                  {...field}
                  onChange={e => {
                    const value = cpfMask(e.target.value);
                    setValue('cpf', value);
                    clearErrors("cpf");
                  }}
                  required={true}    
                  margin={84}        
                  
                  disabled={isDisabled}
                  error={errors["cpf"]?.message}
                />
              );
            }}
          />
        </Row>
        <Row colSize={2}>
          {/* *Obrigatório */}
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                label="Email"
                {...field}
                required={true}    
                margin={84}        
                disabled={isDisabled}
                error={errors["email"]?.message}
              />
            )}
          />
          {/* *Obrigatório */}
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                label="Telefone"
                {...field}
                onChange={e => {
                  const value = phoneMask(e.target.value);
                  setValue('phone', value);
                }}
                required={true}    
                margin={84} 
                disabled={isDisabled}
                error={errors["phone"]?.message}
              />
            )}
          />
        </Row>
        <Row colSize={3}>
          <Controller
            name="value_captured_incentive_laws"
            control={control}
            className="bigText"
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Valor captado via leis"
                disabled={isDisabled}
              />
            )}
          />

          <Select
            className="select-project-name"
            label="Experiências anteriores"
            value={
              getValues('years_experience')
                ? years_experience.findIndex(
                    item => item.value === yearsExperience,
                  )
                : undefined
            }
            options={years_experience.map(item => item.label)}
            onChange={e => {
              setYearsExperience(years_experience[e.target.value].value);
              setValue(
                'years_experience',
                years_experience[e.target.value].value,
              );
            }}
            disabled={isDisabled}
            role="proponente"
          />
          
          <SelectMultiple
            className="select-project-name"
            label="Leis que já trabalhei"
            value={
              typeof getValues('incentive_laws') == 'string'
                ? valuesLawRef.current
                : getValues('incentive_laws')
            }
            laws={laws}
            role="proponente"
            options={laws.map(item => item.description)}
            onChange={e => {
              setSelectedLaws(e);
              setValue('incentive_laws', e);
            }}
            disabled={isDisabled}
          />
        </Row>
      </>
    );
  }, [
    control,
    setValue,
    years_experience,
    laws,
    getValues,
    incentiveLaws,
    yearsExperience,
    errors,
    isUploading,
  ]);

  const renderFiledLegalPerson = useCallback(() => {
    return (
      <>
        <Row colSize={3}>
          {/* *Obrigatório */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                label="Nome Fantasia (PJ) ou Nome Social (PF)"
                {...field}
                disabled={isDisabled}
                error={errors["name"]?.message}
              />
            )}
          />
          <Controller
            name="value_captured_incentive_laws"
            control={control}
            render={({ field }) => (
              <Input {...field} type="number" label="Valor captado via leis" disabled={isDisabled} />
            )}
          />
          <Select
            className="select-project-name"
            label="Experiências anteriores"
            value={
              getValues('years_experience')
                ? years_experience.findIndex(
                    item => item.value === yearsExperience,
                  )
                : undefined
            }
            options={years_experience.map(item => item.label)}
            onChange={e => {
              setYearsExperience(years_experience[e.target.value].value);
              setValue(
                'years_experience',
                years_experience[e.target.value].value,
              );
            }}
            disabled={isDisabled}
            role="proponente"
          />
        </Row>
        <Row colSize={3}>
          {/* *Obrigatório */}
          <Controller
            control={control}
            name="corporate_name"
            render={({ field }) => {
              field.value = getValues('corporate_name');
              return (
                <Input
                  {...field}
                  label="Razão Social"
                  disabled={isDisabled}
                  required={true}    
                  margin={77} 
                  error={errors["corporate_name"]?.message}
                />
              )}}
          />
          
          <Controller
            name="cnpj"
            control={control}
            render={({ field }) => {
              field.value = cnpjMask(getValues('cnpj'));
              return (
                <Input
                  {...field}
                  label="CNPJ"
                  onChange={e => {
                    const value = cnpjMask(e.target.value);
                    setValue('cnpj', value);
                  }}
                  disabled={isDisabled}
                  required={true}    
                  margin={77} 
                  error={errors["cnpj"]?.message}
                />
              );
            }}
          />
          <SelectMultiple
            className="select-project-name"
            label="Leis que já trabalhei"
            value={
              typeof getValues('incentive_laws') == 'string'
                ? valuesLawRef.current
                : getValues('incentive_laws')
            }
            laws={laws}
            role="proponente"
            options={laws.map(item => item.description)}
            onChange={e => {
              setSelectedLaws(e);
              setValue('incentive_laws', e);
            }}
            disabled={isDisabled}
          />
        </Row>
      </>
    );
  }, [
    control,
    setValue,
    getValues,
    laws,
    years_experience,
    incentiveLaws,
    yearsExperience,
    errors,
    isUploading,
  ]);

  return (
    <Container>
      <Typography variant="h6" color="grayDark2">
        Dados cadastrais do Proponente
      </Typography>
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <>
          <Content>
          {/* *Obrigatório */}
            <Row colSize={3}>
              <Select
                className="select-project-name"
                label="Natureza jurídica do proponente"
                value={legalPersons.findIndex(
                  item => item === getValues('legalPerson'),
                )}
                onChange={e => {
                  const value = e.target.value;
                  setPersonType(value);
                  setValue('legalPerson', legalPersons[value]);
                }}

                required={(getValues('legalPerson') != '') ? false : true}
                margin={93}

                options={legalPersons}
                disabled={isDisabled}
              />

                <div style=
                  {localStorage.getItem("clickedNotifier") !== 'visualizado' ?
                    {    
                      padding: '15px 5px 5px 5px',
                      marginTop: '-15px',
                      border: '1px solid #6A06A4',
                      borderRadius: '10px'
                    } :
                    null
                  }
                >
                  <span style=
                    {localStorage.getItem("clickedNotifier") !== 'visualizado' ?
                      {
                        position: 'absolute',
                        marginTop: '-25px',
                        backgroundColor: '#fff',
                        padding: '0 5px',
                        fontFamily: 'Roboto,sans-serif'
                      } :
                      {
                        display: 'none'
                      }
                    }
                  >Novo campo
                  <InfoIcon 
                    data-for="tooltip1" 
                    data-tip="O regime de tributação é um sistema que estabelece a 
                    cobrança de impostos de cada CNPJ, de acordo com o montante 
                    da arrecadação. Além disso, ele também vai depender de 
                    vários outros fatores inerentes ao negócio, como o porte, 
                    o tipo de atividade exercida, o faturamento etc." 
                    multiline={true} 
                    data-html={true} 
                    style={{color: theme.colors.primary, backgroundColor: '#fff', width: '1.35rem', position: 'absolute', marginTop: '-3px'}} />
                  </span>
                  <ReactTooltip id="tooltip1" border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

                  {(personType === 1 || personType === 2) &&
                  <Select
                    className="select-proponent-tax-regime"
                    label="Regime tributário"
                    value={
                      getValues('tax_regime')
                      ? taxs_regime.findIndex(
                        item => item === getValues('tax_regime'))
                      : undefined
                    }
                    
                    onChange={e => {
                      const value = e.target.value;
                      setTaxRegime(taxs_regime[value]);
                      setValue('tax_regime', taxs_regime[value]);
                      setModalOpen(true);
                    }}

                    required={(getValues('tax_regime') != '') ? false : true}
                    margin={93}

                    options={taxs_regime}
                    disabled={localStorage.getItem("clickedNotifier") !== 'visualizado' ? false : isDisabled}
                  />
                  }
                  {isDisabled &&
                    <UpdateTaxRegime
                      open={modalOpen}
                      handleClose={() => {
                        setModalOpen(false)
                      }}
                      onClick={(taxRegime) => {
                        saveTaxRegime()
                        localStorage.setItem("clickedNotifier", "visualizado");
                      }}
                      taxRegime={taxRegime}
                    />
                  }

                </div>
              {personType === 1 && (
                <Select
                    className="select-project-size"
                    label="Porte da empresa"
                    value={companySizes.findIndex(
                      item => item === getValues('company_size'),
                    )}
                    onChange={e => {
                      const value = e.target.value;
                      setCompanySize(value);
                      setValue('company_size', companySizes[value]);
                    }}
                    options={companySizes}
                    disabled={isDisabled}
                />
              )}
            </Row>

            {personType === 0
              ? renderFiledPhysicalPerson()
              : renderFiledLegalPerson()}
              {/* *Obrigatório */}


            <Controller
              name="about"
              control={control}
              render={({ field }) => (
                <Input
                  multiline
                  rows={4}
                  label="Sobre"
                  {...field}
                  disabled={isDisabled}
                  required={true}
                  margin={93}
                  error={errors["about"]?.message}
                />
              )}
              disabled={isDisabled}
              role="proponente"
            />
          </Content>

          <Content>
            <Controller
              name="highlights_and_awards"
              // control={control}
              render={({ field }) => (
                <Input
                  // width="85%"
                  label="Destaques e prêmios"
                  height="65px"
                  multiline
                  rows={4}
                  {...field}
                  disabled={isDisabled}
                />
              )}
            />
          </Content>

          <Content>
            <Typography variant="body1" color="blueGrayish56" className="title">
              Insira aqui a lista de empresas que você atende diretamente e que gostaria que a Incentiv.me não apresentasse o projeto. 
            </Typography>
            <DivMargin style={{ marginTop: 0 }}>
              <EnterpriseAdd>
              <DivAdd>
                  <Input
                    label="Nome da empresa"
                    value={companyNames}
                    onChange={e => setCompanyNames(e.target.value)}
                    disabled={isDisabled}
                    data-tip="Indique todas as empresas que você já possui contato <br/> 
                      e/ou parceria firmada e que por isso, não gostaria que <br/>
                      a Incentiv apresentasse o seu projeto, com a finalidade de <br/>
                      evitar duplicidade de contato." 
                      data-html={true}
                      data-for="tooltip1"
                      data-event='click focus'
                  />
                  <ReactTooltip id="tooltip1" globalEventOff='click' border={true} borderColor={theme.colors.primary} backgroundColor="#fff" textColor={theme.colors.primary} place="top" type="info" effect="solid"/>

                  <Button
                    borderColor={theme.colors.purple}
                    variant="secondary"
                    color={theme.colors.purple}
                    onClick={handleAddCompany}
                    disabled={isDisabled}
                  >
                    INCLUIR
                  </Button>
              </DivAdd>
              </EnterpriseAdd>

                <ContentChip>
                  {companyNamesArray.map(item => (
                    <Chip
                      variant="outlined"
                      label={item}
                      onDelete={() => handleDeleteCompanyNamesArray(item)}
                      disabled={isDisabled}
                    />
                  ))}
                </ContentChip>
          </DivMargin>
        </Content>
      
      <Content>
        <Body>
          <Typography variant="h6" color="grayDark2">
            Localização
          </Typography>
          <Content>
            <LocationTwoRow>
            {/* *Obrigatório */}
              <Controller
                name="cep"
                control={control}
                render={({ field }) => (
                  <Input
                    label="CEP"
                    {...field}
                    required={true}
                    margin={50}
                    value={cepMask(getValues('cep'))}
                    onChange={e => {
                      clearErrors("cep");
                      const value = cepMask(e.target.value);
                      setValue('cep', value);

                      if(value === "")
                        return setCepError(undefined);

                      if(value.length < 9)
                        return setCepError("CEP inválido");

                      if (!disabled) {
                        findCep(value);
                      }
                      // field.onChange(e);
                    }}
                    disabled={isDisabled}
                    error={cepError || errors["cep"]?.message}
                  />
                )}
              />
              {/* *Obrigatório */}
              <Controller
                name="public_place"
                control={control}
                render={({ field }) => <Input label="Logradouro"
                required={true}
                margin={91.5} {...field} disabled={isDisabled} />}
              />
            </LocationTwoRow>
            <LocationThreeRow>
              {/* <Controller
                name="number"
                control={control}
                render={({ field }) => (
                  <Input type="number" 
                    label="Número2"
                    {...field} 
                    onChange={e => {
                      const value = e.target.value;
                      setValue('number', value);
                      if(value.length === 10 && !disabled){
                        setValue('number', limitNumber(value));
                      }
                      // console.log(value.length > 10 ? true : false);
                    }}/>
                )}
              /> */}
              {/* *Obrigatório */}
              <Controller
                name="number"
                control={control}
                render={({ field }) => (
                <Input
                  label="Número"
                  height="23px"
                  inputProps={{ maxLength: 10 }}
                  {...field}
                  onChange={e => {
                    clearErrors("number");
                    const value = onlyNumber(e.target.value);
                    setValue('number', value);
                  }}
                  required={true}
                  margin={50}
                  disabled={isDisabled}
                  error={errors["number"]?.message}
                />
                )}
              />

              {/* *Obrigatório */}
              <Controller
                name="district"
                control={control}
                render={({ field }) => <Input label="Bairro" {...field}
                required={true}
                margin={83}
                disabled={isDisabled} />}
              />
              <Controller
                name="complement"
                control={control}
                render={({ field }) => <Input label="Complemento" {...field} disabled={isDisabled} />}
              />
            </LocationThreeRow>
            <LocationTwoRow>
            {/* *Obrigatório */}
              <Controller
                name="state"
                control={control}
                render={({ field }) => <Input label="UF" {...field} 
                required={true}
                margin={50}
                disabled={isDisabled} />}
              />
              {/* *Obrigatório */}
              <Controller
                name="city"
                control={control}
                render={({ field }) => <Input label="Cidade" {...field}
                required={true}
                margin={91.5}
                disabled={isDisabled} />}
              />
            </LocationTwoRow>
          </Content>
        </Body>
        <div></div>
      </Content>
        <Content>
          {personType !== 0 && (
            <Body>
              {personType === 1 ? (
                <Body>
                  <Typography variant="h6" color="grayDark2">
                    Documentos
                  </Typography>
                  <Content>
                    <Typography variant="body1" color="blueGrayish56" className="title" style={{ marginTop: '1rem' }}>
                      Anexe os documentos abaixo fazendo o upload dos arquivos no formato PDF. Caso os arquivos sejam maiores que 2MB, confira <Link href={'https://site.incentiv.me/wp-content/uploads/2020/12/passo_a_passo_ilovepdf_2020.pdf'} rel="noreferrer" target="_blank">aqui um passo a passo de como comprimi-los</Link> e deixá-los no tamanho adequado
                    </Typography>
                    <DocumentItem>
                      {/* *Obrigatório */}
                      <Typography variant="caption" color="grayDark2">
                      Contrato social *Obrigatório
                      </Typography>
                      <Typography variant="overline" color="grayDark2">
                      Lembre-se que os nomes e os CPFs dos representantes legais devem constar no Contrato Social.
                      </Typography>
                      {/* <FileInput
                        onChange={files => {
                          if (files.length === 1) {
                            setValue('social_contract', files[0]);
                          }
                        }}
                        files={[getValues('social_contract')]}
                        buttonText={
                          getValues('social_contract') ? 'ALTERAR' : 'UPLOAD'
                        }
                        required={[getValues('social_contract')].length > 0 ? true : false}    
                        margin={50}
                        disabled={isDisabled}
                        error={errors["social_contract"]?.message}
                      /> */}
                      <DragAndDrop 
                        placeholder="nenhum arquivo enviado"
                        onChange={files => {
                          clearErrors("social_contract");

                          if(!validateFileExtension(files, ["application/pdf"]))
                            return setError("social_contract", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                          if(!validateFileSize(files, 7))
                            return setError("social_contract", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });
                          if (files.length === 1) {
                            setValue('social_contract', files[0]);

                            clearErrors("social_contract")
                          }
                        }}
                        label={'Contrato social'}
                        link={[getValues('social_contract')]}
                        disabled={isDisabled}
                        isUploading={isUploading}
                        error={errors["social_contract"]?.message}
                      />
                    </DocumentItem>
                  </Content>
                </Body>
              ) : (
                <Body>
                  <Typography variant="h6" color="grayDark2">
                    Documentos
                  </Typography>
                  <Content>
                    <Typography variant="body1" color="blueGrayish56" className="title">
                      Anexe os documentos abaixo fazendo o upload dos arquivos no formato PDF. Caso os arquivos sejam maiores que 2MB, confira <Link href={'https://site.incentiv.me/wp-content/uploads/2020/12/passo_a_passo_ilovepdf_2020.pdf'} rel="noreferrer" target="_blank">aqui um passo a passo de como comprimi-los</Link> e deixá-los no tamanho adequado
                    </Typography>
                    <Row colSize={2}>
                      <DocumentItem>
                        {/* *Obrigatório */}
                        <Typography variant="caption" color="grayDark2">
                        Ata da última eleição *Obrigatório
                        </Typography>
                        <Typography variant="overline" color="grayDark2">
                        Lembre-se que os nomes e os CPFs dos representantes legais devem constar na ata.
                        </Typography>
                        {/* <FileInput
                          onChange={files => {
                            if (files.length === 1) {
                              setValue('last_election', files[0]);
                            }
                          }}
                          files={[getValues('last_election')]}
                          buttonText={
                            getValues('last_election') ? 'ALTERAR' : 'UPLOAD'
                          }
                          required={[getValues('last_election')].length > 0 ? true : false} 
                          margin={21.5}  
                          disabled={isDisabled}
                          error={errors["last_election"]?.message}
                        /> */}
                        <DragAndDrop 
                          onChange={files => {
                            if(!validateFileExtension(files, ["application/pdf"]))
                              return setError("last_election", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                            if(!validateFileSize(files, 7))
                              return setError("last_election", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });
    
                            if (files.length === 1) {
                              setValue('last_election', files[0]);

                              clearErrors("last_election")
                            }
                          }}
                          label={'Ata da última eleição'}
                          link={[getValues('last_election')]}
                          isUploading={isUploading}
                          error={errors["last_election"]?.message}
                        />
                      </DocumentItem>
                      <DocumentItem>
                        {/* *Obrigatório */}
                        <Typography variant="caption" color="grayDark2">
                        Estatuto Social *Obrigatório
                        </Typography>
                        {/* <FileInput
                          onChange={files => {
                            if (files.length === 1) {
                              setValue('social_status', files[0]);
                            }
                          }}
                          files={[getValues('social_status')]}
                          buttonText={
                            getValues('social_status') ? 'ALTERAR' : 'UPLOAD'
                          }
                          required={[getValues('social_status')].length > 0 ? true : false} 
                          margin={21.5}  
                          disabled={isDisabled}
                          error={errors["social_status"]?.message}
                        /> */}
                        <DragAndDrop 
                          onChange={files => {
                            if(!validateFileExtension(files, ["application/pdf"]))
                              return setError("social_status", { message: "Este formato não é compatível. Por favor, insira um arquivo com formato PDF" });
                            if(!validateFileSize(files, 7))
                              return setError("social_status", { message: "O tamanho do arquivo não é compatível. Por favor, insira um arquivo com até 7MB." });
    
                            if (files.length === 1) {
                              setValue('social_status', files[0]);

                              clearErrors("social_status")
                            }
                          }}
                          label={'Estatuto social'}
                          link={[getValues('social_status')]}
                          isUploading={isUploading}
                          error={errors["social_status"]?.message}
                        />
                      </DocumentItem>
                    </Row>
                  </Content>
                </Body>
              )}
            </Body>
          )}
        </Content>
        </>
      )}
    </Container>
  );
};

export default RegistrationData;
